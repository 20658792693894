import React from "react";
import { RotaButtonNew, RotaModal } from "@teamrota/rota-design";

import {
  StyledMainText,
  StyledButtonWrapper
} from "~/src/containers/roles/styles";

const NotificationsConfirmationModal = ({ shiftId, onConfirm, onCancel }) => {
  return (
    <RotaModal isSmall onClose={onCancel}>
      <StyledMainText>
        This will send out a notification to all candidates on the Waitlist
      </StyledMainText>
      <StyledButtonWrapper>
        <RotaButtonNew onClick={onCancel} variant="outlined">
          Cancel
        </RotaButtonNew>
        <RotaButtonNew onClick={() => onConfirm(shiftId)}>
          Confirm
        </RotaButtonNew>
      </StyledButtonWrapper>
    </RotaModal>
  );
};

export default NotificationsConfirmationModal;
